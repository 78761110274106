
import styled from "styled-components"
import LeftList from "./LeftList"

const Container = styled.div`
    height: 100vh;
    width: 71%;
    top: 0em;
    z-index: 5;
`

export default function Left() {
    return (
        <Container>
            <LeftList />
        </Container>
    )
}