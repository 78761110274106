import { setLeftList, setRightList } from "./listActions"


export const RESET_SETTINGS = "RESET_SETTINGS"
export const INITIATE_SONG_TIER = "INITIATE_SONG_TIER"
export const TOGGLE_RIGHT_DRAWER = "TOGGLE_RIGHT_DRAWER"
export const TOGGLE_SETTINGS_DRAWER = "TOGGLE_SETTINGS_DRAWER"
export const SET_BACKGROUND = "SET_BACKGROUND"
export const SET_SPEED = "SET_SPEED"
export const SET_INTERVAL = "SET_INTERVAL"
export const CHANGE_MAX = "CHANGE_MAX"


export const initiateSongTier = () => ({
    type: INITIATE_SONG_TIER
})
export const resetSettings = () => ({
    type: RESET_SETTINGS
})


export const toggleRightDrawer = () => ({
    type: TOGGLE_RIGHT_DRAWER
})

export const toggleSettingsDrawer = () => ({
    type: TOGGLE_SETTINGS_DRAWER
})

export const setBackground = (type, value) => ({
    type: SET_BACKGROUND,
    payload: { type, value }
})

export const setSpeed = (val) => ({
    type: SET_SPEED,
    payload: val
})

export const setAnimationInterval = (val) => ({
    type: SET_INTERVAL,
    payload: val
})

export const changeMax = (val) => ({
    type: CHANGE_MAX,
    payload: val
})

export const setChangeMax = (max) => {
    return (dispatch, getState) => {
        let left = getState().list.left.slice()
        let right = getState().list.right.slice()
        if (left.length > max) {
            right = [...left.slice(max), ...right]
            left = left.slice(0, max)
        };
        while (left.length + right.length > max * 2) {
            right = right.slice(0, max * 2 - left.length)
        }
        dispatch(setLeftList(left))
        dispatch(setRightList(right))
        dispatch(changeMax(max))
    }
}