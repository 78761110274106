import { useDispatch } from "react-redux"
import styled from "styled-components"

import ClearAllIcon from '@material-ui/icons/ClearAll';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { IconButton, Tooltip } from "@material-ui/core";

import { resetAnimation } from '../../actions/animationActions';
import { clearAll, moveEverything } from "../../actions/listActions";

const Nav = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`

export default function RightNav() {

    const dispatch = useDispatch()

    return (
        <Nav>
            <div className="move">
                <Tooltip title="Move all out" >
                    <IconButton onClick={() => dispatch(moveEverything("left"))}>
                        <DoubleArrowIcon fontSize="small" style={{ transform: "scale(-1, 1)" }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Move all back" >
                    <IconButton onClick={() => dispatch(moveEverything("right"))}>
                        <DoubleArrowIcon fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
            <Tooltip title="Clear All" >
                <IconButton onClick={() => {
                    dispatch(clearAll())
                    dispatch(resetAnimation())
                }}>
                    <ClearAllIcon />
                </IconButton>
            </Tooltip>
        </Nav>
    )
}