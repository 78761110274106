

export const TOGGLE_ANIMATION = "TOGGLE_ANIMATION"
export const ADD_COUNT = "ADD_COUNT"
export const SET_TILE_WIDTH = "SET_TILE_WIDTH"

export const OFF_RESET = "OFF_RESET"
export const RESET_ANIMATION = "RESET_ANIMATION"

export const ADD_NEW_COUNT = "ADD_NEW_COUNT"
export const TOGGLE_NEW_SONG = "TOGGLE_NEW_SONG"
export const MOVE_NEW_SONG = "MOVE_NEW_SONG"

export const toggleAnimation = (list) => ({
    type: TOGGLE_ANIMATION,
    payload: list
})

export const addCount = () => ({
    type: ADD_COUNT
})

export const setTileWidth = (width) => ({
    type: SET_TILE_WIDTH,
    payload: width
})

export const resetAnimation = () => ({
    type: RESET_ANIMATION
})

export const addNewCount = () => ({
    type: ADD_NEW_COUNT
})

export const toggleNewSong = (bool) => ({
    type: TOGGLE_NEW_SONG,
    payload: bool
})

export const toggleMovingNewSong = (bool) => ({
    type: MOVE_NEW_SONG,
    payload: bool
})