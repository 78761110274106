import { moveLeftListCheck, moveRightListCheck, setLeftList, setRightList } from "../actions/listActions";
import reorder from "./reorderList";


export default function handleDragEnd(dispatch, result, states) {
    const { source, destination } = result;
    const { rightList, leftList, max } = states

    const RIGHT_LIST = "rightList"
    const LEFT_LIST1 = "leftList1"
    const LEFT_LIST2 = "leftList2"

    let newItems

    switch (source.droppableId) {
        case RIGHT_LIST:
            switch (destination.droppableId) {
                case RIGHT_LIST:
                    newItems = reorder(rightList, source.index, destination.index)
                    dispatch(setRightList(newItems))
                    break;
                case LEFT_LIST1:
                    dispatch(moveLeftListCheck(rightList[source.index], destination.index, source.index))
                    break;
                case LEFT_LIST2:
                    dispatch(moveLeftListCheck(rightList[source.index], max / 2 + destination.index, source.index))
                    break;
                default:
                    break;
            }
            break;
        case LEFT_LIST1:
            switch (destination.droppableId) {
                case RIGHT_LIST:
                    dispatch(moveRightListCheck(leftList[source.index], destination.index, source.index))
                    break;
                case LEFT_LIST1:
                    newItems = reorder(leftList, source.index, destination.index)
                    dispatch(setLeftList(newItems))
                    break;
                case LEFT_LIST2:
                    newItems = reorder(leftList, source.index, destination.index + max / 2 - 1);
                    dispatch(setLeftList(newItems))
                    break;
                default:
                    break;
            }
            break;
        case LEFT_LIST2:
            switch (destination.droppableId) {
                case RIGHT_LIST:
                    dispatch(moveRightListCheck(leftList[source.index + max / 2], destination.index, source.index + max / 2))
                    break;
                case LEFT_LIST1:
                    newItems = reorder(leftList, source.index + max / 2, destination.index);
                    dispatch(setLeftList(newItems))
                    break;
                case LEFT_LIST2:
                    newItems = reorder(leftList, source.index + max / 2, destination.index + max / 2);
                    dispatch(setLeftList(newItems))
                    break;
                default:
                    break;
            }
            break;
        default:
            break;
    }
}