import {
    RESET_ANIMATION,
    ADD_COUNT,
    SET_TILE_WIDTH,
    TOGGLE_ANIMATION,
    TOGGLE_NEW_SONG,
    ADD_NEW_COUNT,
    MOVE_NEW_SONG
} from "../actions/animationActions"

const initialState = {
    on: "stop",
    count: 0,
    tileWidth: 0,
    reset: false,
    newCount: 0,
    newSongs: false,
    movingNewSong: false
}

export default function animationReducer(state = initialState, action) {
    switch (action.type) {
        case TOGGLE_ANIMATION:
            return { ...state, on: action.payload === "stop" ? "stop" : state.on === "play" ? "pause" : "play", reset: true }
        case ADD_COUNT:
            return { ...state, count: state.count + 1 }
        case SET_TILE_WIDTH:
            return { ...state, tileWidth: action.payload }
        case RESET_ANIMATION:
            return { ...initialState, tileWidth: state.tileWidth }
        case TOGGLE_NEW_SONG:
            return { ...state, newSongs: action.payload }
        case ADD_NEW_COUNT:
            return { ...state, newCount: state.newCount + 1 }
        case MOVE_NEW_SONG:
            return { ...state, movingNewSong: action.payload }
        default:
            return state
    }
}