import { useSelector } from "react-redux"
import styled, { css, keyframes } from "styled-components"

const Container = styled.div`
    position: relative;
    left: 0%;
    display: flex;
    align-items: center;
    border-radius: 5px;
    transition: left 300ms ease-out;
    color: white;
    backdrop-filter: blur(2px);
    font-size: ${props => props.twenty ? "1.1rem" : props.thirty ? ".9rem" : ".7rem"};    
    padding: .2em .3em;
    gap: .5em;
    height: ${props => props.twenty ? "60px" : props.thirty ? "42px" : "26px"};

    &:hover { 
        background-color: ${props => props.animating ? "transparent" : "#00000065"};
    }

    .info {
        display: flex;
        flex: 1;
        min-width: 0;
        flex-direction: ${props => props.fifty ? "row" : "column"};
        align-items: ${props => props.fifty ? "center" : "flex-start"};
        gap: ${props => props.fifty ? "1em" : "0em"};
        margin-left: 1em;
    }

    .title {
        width: 90%;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .artist {
        font-size: 0.7em;
        opacity: 0.8;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .imgdiv {
        display: flex;
        width: ${props => props.twenty ? "3em" : props.thirty ? "2.5em" : "1.8em"};   
        height: ${props => props.twenty ? "3em" : props.thirty ? "2.5em" : "1.8em"};   

        img {
            object-fit: cover;
        }
    }

    .rank {
        padding: .5em;
        font-size: ${props => props.twenty ? "1.2em" : props.thirty ? "1.1em" : "1em"};    
        display: flex;
        justify-content: center;
        align-items: center;
        width: 7vh;
        height: 100%;
        line-height: 1;
        font-weight: 700;
    }

`

const scoreAni = keyframes`
    0% {
    opacity: 0;
    -webkit-transform: scale(1, 1) translateY(-900%);
    transform: scale(1, 1) translateY(-900%);
    }

    50%,
    70%,
    90% {
        opacity: 1;
        -webkit-transform: scale(1.1, 1.1) translateY(0);
        transform: scale(1.1, 1.1) translateY(0);
    }

    60%,
    80%,
    100% {
        opacity: 1;
        -webkit-transform: scale(1, 1) translateY(0);
        transform: scale(1, 1) translateY(0);
    }
`

const Score = styled.div`
    width: 100px;
    height: 90%;
    display: flex;
    white-space: nowrap;
    align-items: center;
    justify-content: center;
    font-weight: 900;
    line-height: 1;
    margin: .2em;
    margin-right: .5em;
    padding-bottom: 2px;
    font-size: ${props => props.fifty ? "1.2rem" : props.thirty ? "1.4rem" : "1.5rem"};
    border-radius: 3px;
    opacity: 0;
    color: white;
    transition: opacity 0s 1s;

    ${props => props.showScore && css`
        opacity: 1;
        color: #f0b81eeb;
    `}

    ${props => props.dragging && css`
        animation: ${scoreAni} 1s linear 1s 1 forwards;
        color: white;
    `}  
`

export default function LeftItem({ track, index, side }) {

    let max = useSelector(state => state.settings.max)
    const animation = useSelector(state => state.animation.on)

    const formatScore = (score) => {
        if (score < 1e3) return Math.floor(score)
        if (score < 1e6) return parseFloat((score / 1e3).toFixed(2)) + "K"
        if (score < 1e9) return parseFloat((score / 1e6).toFixed(2)) + "M"
        if (score < 1e12) return parseFloat((score / 1e9).toFixed(2)) + "B"
        if (score > 1e15) return "999.99T"
        return parseFloat((score / 1e12).toFixed(2)) + "T"
    }

    return (
        <Container twenty={max === 20} animating={animation === "play"} fifty={max === 50} thirty={max === 30}>
            <div className="rank">
                {side === "left" ? index + 1 : index + max / 2 + 1}
            </div>
            <div className="imgdiv">
                <img src={track.album.images[0].url} alt="album art" />
            </div>
            <div className="info">
                <div className="title">{track.name}</div>
                <div className="artist">{track.artists.map(el => el.name).join(" | ")}</div>
            </div>
            <Score showScore={track.showScore} dragging={track.isDragged} thirty={max === 30} fifty={max === 50}>{track.score === "" ? 0 : formatScore(track.score)}</Score>
        </Container>
    )
}