import { useDispatch, useSelector } from "react-redux";

import { Slider } from "@material-ui/core";

import { setSpeed } from "../../actions/settingsActions";

export default function Speed() {

    const dispatch = useDispatch()
    const speed = useSelector(state => state.settings.speed)

    return (
        <div className="speed">
            <h3 id="speed-slider">
                Speed
            </h3>
            <Slider
                style={{ width: "70%" }}
                getAriaValueText={(val) => val}
                aria-labelledby="speed-slider"
                valueLabelDisplay="auto"
                value={speed}
                step={10}
                marks
                min={10}
                max={100}
                onChange={(e, val) => dispatch(setSpeed(val))}
            />
        </div>
    )
}