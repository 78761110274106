import { useDispatch, useSelector } from "react-redux";

import { Fab, withStyles } from "@material-ui/core";

import { resetAnimation } from "../actions/animationActions";
import { resetLeftList, resetScores } from "../actions/listActions";

const ResetFab = withStyles((theme) => ({
    root: {
        color: "#080808eb",
        backgroundColor: "#f0b81eeb",
        '&:hover': {
            backgroundColor: "#f03d1eeb",
            color: "white"
        },
        position: "fixed",
        bottom: "7.5em",
        right: "2.6em",
        zIndex: "8",
        fontWeight: "700",
        padding: "0em 1em"
    },
}))(Fab);


export default function ResetButton() {

    const onSwitch = useSelector(state => state.animation.on)
    const reset = useSelector(state => state.animation.reset)

    const dispatch = useDispatch()

    const resetClicked = () => {
        dispatch(resetScores())
        dispatch(resetLeftList())
        dispatch(resetAnimation())
    }

    return (
        <ResetFab
            disabled={!reset || onSwitch === "play"}
            variant="extended"
            size="small"
            onClick={resetClicked}
        >
            Reset
        </ResetFab>
    )
}