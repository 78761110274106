import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"

import { toggleRightDrawer } from "../actions/settingsActions"
import { toggleSettingsDrawer } from "../actions/settingsActions"

const Container = styled.div`
    width: 45px;
    height: 45px;
    position: absolute;
    top: ${props => props.settings ? "5rem" : "1.2em"};
    right: 1.8em;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: ${props => props.settingsOpen ? "rotate(360deg)" : "rotate(0deg)"};
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out, opacity 100ms;
    cursor: pointer;
    z-index: 5;
    opacity: .3;

    &:hover {
        opacity: 1;
    }

    span {
        display: block;
        position: absolute;
        height: 8px;
        width: 100%;
        background-color: white;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
    }

    & > span:nth-child(1) {
        top: 0px;
    }

    & > span:nth-child(2) {
        top: 15px;
    }

    & > span:nth-child(3) {
        top: 30px;
    }

    &.open {
        opacity: 1;

        & > span {
            background-color: rgb(39,44,52);
        }
        
        & > span:nth-child(1) {
            top: 15px;
            -webkit-transform: rotate(135deg);
            -moz-transform: rotate(135deg);
            -o-transform: rotate(135deg);
            transform: rotate(135deg);
        }

        & > span:nth-child(2) {
            opacity: 0;
            left: -60px;
        }

        & > span:nth-child(3) {
            top: 15px;
            -webkit-transform: rotate(-135deg);
            -moz-transform: rotate(-135deg);
            -o-transform: rotate(-135deg);
            transform: rotate(-135deg);
        }
    }
`

export default function Burger({ settings }) {

    let rightDrawer = useSelector(state => state.settings.rightDrawer)
    let settingsDrawer = useSelector(state => state.settings.settingsDrawer)
    let dispatch = useDispatch()

    return (
        <Container onClick={() => settings ? dispatch(toggleSettingsDrawer()) : dispatch(toggleRightDrawer())} settingsOpen={settingsDrawer && settings} settings={settings} className={settings || rightDrawer ? "open" : ""}>
            <span></span>
            <span></span>
            <span></span>
        </Container>
    )
}