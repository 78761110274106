import styled from "styled-components"
import { useHistory } from "react-router"

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: rgb(39,44,52);
    display: flex;
    justify-content: center;
    align-items: center;
`

const Center = styled.div`
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`

const Button = styled.div`
    position: relative;
    width: 400px;
    height: 100px;
    cursor: pointer;
    margin: 20px 50px;

    .front {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 150px;
        box-shadow: 5px 5px 20px #00000061;
        padding: 10px;
        bottom: 0;
        right: 0;
        transition: bottom 200ms ease-in, right 200ms ease-in;

        h1 {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 150px;
            letter-spacing: 1.5px;
            border: 5px solid rgb(39,44,52);
        }

    }

    .back {
        position: absolute;
        bottom: -10%;
        right: -2%;
        background-color: #f59f00;
        width: 100%;
        border-radius: 150px;
        height: 100%;
        transition: bottom 200ms ease-in, right 200ms ease-in;
    }

    &:hover {
        .front {
            background-color: #f59f00;
            color: white;
            bottom: -10%;
            right: -2%;

            h1 {
                border-color: white;
            }
        }

        .back {
            background-color: white;
            bottom: 0;
            right: 0;
        }
    }

`

export default function Landing() {

    const history = useHistory()

    return (
        <Container>
            <Center>
                <Button onClick={() => history.push("/songtier")}>
                    <div className="back"></div>
                    <div className="front">
                        <h1>
                            Song Tier
                        </h1>
                    </div>
                </Button>
                <Button onClick={() => history.push("/scoreboard")}>
                    <div className="back"></div>
                    <div className="front">
                        <h1>
                            Scoreboard
                        </h1>
                    </div>
                </Button>
            </Center>
        </Container>
    )
}