
import { useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HexColorPicker } from "react-colorful";
import styled from "styled-components"

import CameraAltRoundedIcon from '@material-ui/icons/CameraAltRounded';

import { setBackground } from "../../actions/settingsActions";
import useClickOutside from "../../helpers/useClickOutside";

import backgroundImg from "../../background.jpg"

const Radio = styled.div`
display: grid;
grid-template-columns: repeat(2, minmax(0, 1fr));
gap: 1em;

.choice {
    border: 3px solid #aeb6c5;
    border-radius: 5px;
    height: 100px;
    cursor: pointer;

    &:hover {
        border: 3px solid rgb(39,44,52);
    }
}

.color-picker {
    position: relative;

    .picker {
        position: absolute;
        top: -50%;
        left: -.5em;
    }
}

.label {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    cursor: pointer;
    color: #aeb6c5;
    
        h4 {
            margin-top: 3px;
            padding: .2em 1em;
            background-color: #ffffff;
            border-radius: 20px;

            &.upload {
                padding: .2em .5em;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: .5em;
            }
        }
    
    &:hover {
        color: rgb(39,44,52);
    }
}

.invisible {
    border: none;
    cursor: auto;

    &:hover {
        border: none;
    }
}

.album-cover {
    background-image: url(${backgroundImg});
}

.active {
    border: 3px solid rgb(39,44,52);

    .label {
        color: rgb(39,44,52);
    }
}
`


export default function Background() {

    const dispatch = useDispatch()

    const backgroundType = useSelector(state => state.settings.backgroundType)

    const [colorOpen, setColorOpen] = useState(false)
    const picker = useRef();
    const close = useCallback(() => setColorOpen(false), []);
    useClickOutside(picker, close)


    const [color, setColor] = useState("#272C34")

    const background = (type, val) => {
        dispatch(setBackground(type, val))
        if (type === "solid-color") setColor(val)
    }

    return (
        <div className="background">
            <h3>
                Background
            </h3>
            <Radio>
                <div className={backgroundType === "album-cover" ? "active choice album-cover" : "choice album-cover"}>
                    <div className="label" onClick={() => background("album-cover")}>
                        <h4>#1 Song Album</h4>
                    </div>
                </div>
                <div className={backgroundType === "solid-color" ? "active choice color-picker" : "choice color-picker"} style={{ backgroundColor: color }}>
                    <div className="label" onClick={() => {
                        background("solid-color", color)
                        setColorOpen(true)
                    }}>
                        <h4>Pick a color</h4>
                    </div>
                    {colorOpen &&
                        <div className="picker" ref={picker}>
                            <HexColorPicker color={color} onChange={(val) => background("solid-color", val)} />
                        </div>
                    }
                </div>
                <div className={backgroundType === "upload" ? "active choice" : "choice"}>
                    <label className="label" htmlFor="upload-button">
                        <h4 className="upload">
                            <CameraAltRoundedIcon />
                            Upload picture
                        </h4>
                    </label>
                    <input accept="image/*" style={{ display: "none" }} id="upload-button" type="file" onChange={(e) => background("upload", e.target.files[0])} />
                </div>
                <div className="choice invisible"></div>
            </Radio>
        </div>
    )
}