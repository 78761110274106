import styled from "styled-components"
import { useSelector } from "react-redux"
import { Draggable, Droppable } from "react-beautiful-dnd"

import LeftItem from "./LeftItem"

const Container = styled.div`
    position: relative;
    width: 48%;
    height: 100%;
    min-height: 90vh;
    z-index: 3;

    .Box {
        width: 100%;
    }

    &.half {
        width: 100%;

        .Box {
            width: 48%;
        }
    }
`

export default function Column({ side }) {
    const list = useSelector(state => state.list.left)
    const max = useSelector(state => state.settings.max)

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        margin: `0 0 1.6px 0`,
        borderRadius: "5px",
        backgroundColor: isDragging ? '#3d81ff' : "rgba(0,0,0,.6)",
        ...draggableStyle
    });

    let sideList = side === "left" ? list.slice(0, max / 2) : list.slice(max / 2)

    return (
        <Droppable droppableId={side === "left" ? "leftList1" : "leftList2"}>
            {(provided, snapshot) => (
                <Container
                    ref={provided.innerRef}
                    className={list.length < max / 2 ? "half" : ""}
                >
                    {sideList.map((track, index) => (
                        <Draggable
                            key={"left" + track.id}
                            draggableId={track.id}
                            index={index}>
                            {(provided, snapshot) => (
                                <div className="Box"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                >
                                    <LeftItem key={track.id} side={side} index={index} track={track} />
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </Container>
            )}
        </Droppable>
    )
}