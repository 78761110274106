import styled from "styled-components"
import { useSelector } from "react-redux";

import RightList from "./RightList"
import Searchbar from "./Searchbar"
import RightNav from "./RightNav";

const Container = styled.div`
    position: fixed;
    right: ${props => props.open ? "0%" : "-30%"};
    flex-shrink: 0;
    max-width: 450px;
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    align-items: center;
    background-color: white;
    z-index: 4;
    height: 100vh;
    transition: all 250ms ease-in-out;

    h1 {
        font-size: 2.2rem;
    }
`

const Header = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    align-items: center;
    width: 100%;
    padding: 5em 2em 0em 2em;
    position: sticky;
    top: 0em;
    z-index: -1;
`

export default function RightDrawer({ page }) {

    const rightDrawer = useSelector(state => state.settings.rightDrawer)

    return (
        <Container open={rightDrawer}>
            <Header>
                <h1>{page}</h1>
                <Searchbar />
                <RightNav />
            </Header>
            <RightList page={page} />
        </Container>
    )
}