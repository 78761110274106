
import styled from "styled-components"
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { clearAll, replaceWithNewSong, showScore } from "../actions/listActions";
import { addCount, addNewCount, resetAnimation, toggleAnimation, toggleMovingNewSong, toggleNewSong } from "../actions/animationActions";

import Left from "./Left"
import Background from "./Background";
import SettingsButton from "./SettingsButton";
import Burger from "./Burger";
import ResetButton from "./ResetButton"
import PlayButton from "./PlayButton"
import RightDrawer from "./RightDrawer/RightDrawer"
import Settings from "./SettingsDrawer/Settings"
import ErrorAlert from "./ErrorAlert";
import NewSong from "./NewSong";

import startDrag from "../helpers/startDrag";
import handleDragEnd from "../helpers/handleDragEnd";
import { resetSettings } from "../actions/settingsActions";
import useDocumentTitle from "../helpers/useDocumentTitle";

const Container = styled.div`
    position: relative;
    overflow-x: hidden;
    width: 100%;
    min-height: 100vh;
    background-color: rgb(39,44,52);
    display: flex;
`

const Cover = styled.div`
  z-index: 8;
  position: fixed;
  width: 100vw;
  height: 100vh;
`


export default function Scoreboard() {

  const leftList = useSelector(state => state.list.left)
  const rightList = useSelector(state => state.list.right)
  const newSongsList = useSelector(state => state.list.newSongs)

  const onSwitch = useSelector(state => state.animation.on)
  const count = useSelector(state => state.animation.count)
  const tileWidth = useSelector(state => state.animation.tileWidth)
  const reset = useSelector(state => state.animation.reset)
  const newCount = useSelector(state => state.animation.newCount)
  const newSongPopUp = useSelector(state => state.animation.newSongs)
  const movingNewSong = useSelector(state => state.animation.movingNewSong)

  const speed = useSelector(state => state.settings.speed)
  const interval = useSelector(state => state.settings.interval)
  const max = useSelector(state => state.settings.max)

  const dispatch = useDispatch()

  let onDragEnd = result => {

    if (!result.destination) {
      return;
    }

    const dragEndStates = {
      rightList,
      leftList,
      max
    }

    handleDragEnd(dispatch, result, dragEndStates)

    if (movingNewSong) {
      dispatch(toggleMovingNewSong(false))
    }

  };

  const onDragStart = () => {
    if (reset) {
      if (onSwitch === "pause" || onSwitch === "stop") {
        dispatch(resetAnimation())
      }
    }
  }

  const preDragStates = {
    leftList,
    max,
    speed,
    tileWidth,
    count
  }

  let api;
  const useMyCoolSensor = value => {
    api = value;
  };

  const playAnimation = () => {
    dispatch(showScore())

    const item = leftList[leftList.length - 1]
    startDrag(api, item, preDragStates)

    if (count < leftList.length) {
      dispatch(addCount())
    }
  }

  const animationInterval = interval === "" ? 0 : (interval * 1000)

  const showNewSong = () => {
    dispatch(toggleNewSong(true))
    setTimeout(() => moveNext(), 3000 + animationInterval)
  }

  function moveNext() {
    dispatch(replaceWithNewSong())
    dispatch(toggleMovingNewSong(true))
    dispatch(toggleNewSong(false))
  }

  useEffect(() => {
    if (onSwitch === "play" && count < leftList.length && !leftList[leftList.length - 1].showScore) {
      setTimeout(() => playAnimation(), animationInterval)
    }
    else if (onSwitch === "play" && count === leftList.length && newCount === newSongsList.length) {
      setTimeout(() => dispatch(toggleAnimation("stop")), animationInterval)
    }
    else if (onSwitch === "play" && movingNewSong && !leftList[leftList.length - 1].showScore) {
      playAnimation()
      dispatch(addNewCount())
    }
    else if (!newSongPopUp && !movingNewSong && onSwitch === "play" && count === leftList.length && newCount < newSongsList.length) {
      setTimeout(() => showNewSong(), 1000 + animationInterval)
    }
    // eslint-disable-next-line
  }, [onSwitch, leftList, movingNewSong])

  useDocumentTitle("Scoreboard")

  useEffect(() => {
    dispatch(resetSettings())
    dispatch(clearAll())
    dispatch(resetAnimation())
    // eslint-disable-next-line
  }, [])

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart} sensors={[useMyCoolSensor]} className="App">
      {onSwitch === "play" &&
        <Cover></Cover>
      }
      <Burger />
      <SettingsButton />
      <Container>
        <Background />
        <Left />
        <RightDrawer page="Scoreboard" />
        <Settings />
      </Container>
      <ResetButton />
      <PlayButton />
      <ErrorAlert />
      {newSongPopUp &&
        <NewSong />
      }
    </DragDropContext>
  )
}
