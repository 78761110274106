
import { useRef } from "react"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"

import { setTileWidth } from "../actions/animationActions"

import Column from "./Column"

const Container = styled.div`
    height: 100vh;
    display: flex;
    gap: ${props => props.fifty ? "25px" : "32px"};
    padding: ${props => props.fifty ? "8px" : "32px"};
    top: 0em;
    z-index: 5;
    width: ${props => props.small ? "71%" : "92%"};
    transition: width 250ms ease-in-out;
`

export default function Left() {

    const max = useSelector(state => state.settings.max)
    const list = useSelector(state => state.list.left)
    const rightDrawer = useSelector(state => state.settings.rightDrawer)
    const settingsDrawer = useSelector(state => state.settings.settingsDrawer)
    const dispatch = useDispatch()
    
    const ref = useRef()

    const gap = {
        20: 32,
        30: 32,
        50: 25
    }

    const padding = {
        20: 64,
        30: 64,
        50: 16
    }
    
    function updateWidth() {
        dispatch(setTileWidth((ref.current.offsetWidth - gap[max]-padding[max]) / 2 *.99))
    }

    useEffect(() => {
        setTimeout(() => {
            updateWidth()
        }, 500)
        // eslint-disable-next-line
    }, [rightDrawer, settingsDrawer, max])

    useEffect(() => {
        window.addEventListener("resize", updateWidth)
        return () => window.removeEventListener("resize", updateWidth)
        // eslint-disable-next-line
    }, [])


    return (
        <Container ref={ref} fifty={max === 50} small={rightDrawer || settingsDrawer} >
            <Column side="left" />
            {list.length >= max/2 &&
                <Column side="right" />
            }
        </Container>
    )
}