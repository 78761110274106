
import { useSelector } from "react-redux"
import styled from "styled-components"

const Container = styled.div`
    position: fixed;
    display: flex;
    height: 100vh;
    z-index: 1;
    background-color: ${props => props.hasColor ? "#949494" : "transparent"};
    width: ${props => props.small ? "71%" : "100%"};
    transition: width 250ms ease-in-out;

    img {
        opacity: .8;
        filter: blur(3px);
        width: 100%;
        object-fit: cover;
    }
`

export default function Background() {

    const backgroundType = useSelector(state => state.settings.backgroundType)
    const backgroundValue = useSelector(state => state.settings.backgroundValue)
    const rightDrawer = useSelector(state => state.settings.rightDrawer)
    const leftList = useSelector(state => state.list.left)
    const settingsDrawer = useSelector(state => state.settings.settingsDrawer)
    const first = leftList[0]

    return (
        <Container hasColor={first !== undefined || backgroundType === "upload"} small={rightDrawer || settingsDrawer}>
            {backgroundType === "album-cover" && first ?
                <img src={first.album.images[0].url} alt="winner album cover" />
                : backgroundType === "solid-color" ?
                    <div style={{ backgroundColor: backgroundValue, width: "100%", height: "100%" }}></div>
                    : backgroundType === "upload" ?
                        <img src={URL.createObjectURL(backgroundValue)} alt="uploaded" />
                        : null
            }
        </Container>
    )
}