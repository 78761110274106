
import styled, { keyframes } from "styled-components"
import { useSelector } from "react-redux";

import FiberNewOutlinedIcon from '@material-ui/icons/FiberNewOutlined';

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 9;
    top: 0;
    left: 0;
    background-color: #000000b5;
    display: flex;
    justify-content: center;
    align-items: center;
`

const appear = keyframes`
    0% {
    opacity: 0;
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scale(0, 0) rotate(360deg) translateY(100%);
    transform: scale(0, 0) rotate(360deg) translateY(100%);
  }

  30% {
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    -webkit-transform: scale(0, 0) rotate(360deg) translateY(100%);
    transform: scale(0, 0) rotate(360deg) translateY(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(1, 1) rotate(0deg) translateY(0);
    transform: scale(1, 1) rotate(0deg) translateY(0);
  }
`

const Song = styled.div`
    display: flex;
    align-items: center;
    border-radius: 5px;
    color: white;
    backdrop-filter: blur(2px);
    background-color: #f3b711f6;
    max-width: 65vw;
    min-width: 45vw;
    height: 15vh;
    padding: 1em;
    animation: ${appear} 2s ease-out;
    animation-fill-mode: forwards;
    gap: 1em;

    .info {
        display: flex;
        flex: 1;
        min-width: 0;
        flex-direction: column;
        align-items: flex-start;
        font-size: 2em;
    }

    .title {
        width: 90%;
        font-weight: 700;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .artist {
        font-size: 0.7em;
        opacity: 0.8;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .imgdiv {
        display: flex;
        width: 12vh;  
        height: 12vh; 
        

        img {
            object-fit: cover;
            border-radius: 5px;
        }
    }
`

export default function NewSong() {

    const newSongsList = useSelector(state => state.list.newSongs)
    const newCount = useSelector(state => state.animation.newCount)
    let track = newSongsList[newCount]

    return (
        <Container>
            {track &&
                <Song>
                    <FiberNewOutlinedIcon style={{ fontSize: "10vh" }} />
                    <div className="imgdiv">
                        <img src={track.album.images[0].url} alt="album art" />
                    </div>
                    <div className="info">
                        <div className="title">{track.name}</div>
                        <div className="artist">{track.artists.map(el => el.name).join(" | ")}</div>
                    </div>
                </Song>
            }
        </Container>
    )
}