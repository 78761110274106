import styled from "styled-components"
import { useDispatch } from "react-redux";
import { useCallback, useRef, useState } from "react";

import { IconButton } from "@material-ui/core"
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ClearIcon from '@material-ui/icons/Clear';

import Front from "./Front";
import Back from "./Back";

import { removeRightSong } from "../../../actions/listActions";

import useClickOutside from "../../../helpers/useClickOutside";


const Container = styled.div`
    position: relative;
    left: ${props => props.removing ? "100%" : "0%"};
    width: 100%;
    border: ${props => props.new ? "3px solid #d1b463f6" : "3px solid #656c79ab"};
    border-radius: 5px;
    transition: left 300ms ease-out;
    box-shadow: 1px 2px 8px #0000002b;
    overflow: hidden;

    &:hover { 
        border: ${props => props.new ? "3px solid #f3b711f6" : "3px solid #656c79"};
        background-color: #e2e6ee;
    }
`

const LongDiv = styled.div`
    position: relative;
    width: 200%;
    display: flex;
    right: ${props => props.open ? "calc(100% - 60px)" : "0%"};
    transition: right 200ms linear;
    align-items: center;

`

const MoreBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    transform: ${props => props.open ? "scale(-1, 1)" : "scale(1)"};
`


export default function RightItem({ track, index, page }) {

    let [removing, setRemoving] = useState(false)
    let [open, setOpen] = useState(false)
    let dispatch = useDispatch()

    let ref = useRef()

    let removeSong = (id) => {
        setRemoving(true)
        setTimeout(() => dispatch(removeRightSong(id)), 300)
    }

    const close = useCallback(() => setOpen(false), []);
    useClickOutside(ref, close)

    return (
        <Container ref={ref} new={track.new} removing={removing}>
            {
                page === "Song Tier" ?
                    <LongDiv open={open} songtier>
                        <Front track={track} />
                        <div className="close">
                            <IconButton color="action" onClick={() => removeSong(track.id)}>
                                <ClearIcon />
                            </IconButton>
                        </div>
                    </LongDiv>
                    :
                    <LongDiv open={open}>
                        <Front track={track} />
                        <MoreBtn open={open}>
                            <IconButton color="default" onClick={() => setOpen(p => !p)}>
                                <ChevronRightIcon />
                            </IconButton>
                        </MoreBtn>
                        <Back track={track} index={index} removeSong={removeSong} />
                    </LongDiv>
            }
        </Container>
    )
}