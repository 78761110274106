import { useDispatch, useSelector } from "react-redux"

import { toggleAnimation } from "../actions/animationActions"
import { resetScores, storeCurrentLists } from "../actions/listActions"

import { Fab, Tooltip, withStyles } from "@material-ui/core"
import PauseCircleOutlineRoundedIcon from '@material-ui/icons/PauseCircleOutlineRounded';
import PlayCircleOutlineRoundedIcon from '@material-ui/icons/PlayCircleOutlineRounded';

const CustomFab = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
        backgroundColor: "white",
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: "white"
        },
        position: "fixed",
        bottom: "2em",
        right: "2.6em",
        zIndex: "8"
    },
}))(Fab);

export default function PlayButton() {

    const leftList = useSelector(state => state.list.left)
    const onSwitch = useSelector(state => state.animation.on)
    const count = useSelector(state => state.animation.count)
    const newCount = useSelector(state => state.animation.newCount)
    const newSongsList = useSelector(state => state.list.newSongs)

    const dispatch = useDispatch()

    const clickPlay = () => {
        if (onSwitch === "stop") {
            dispatch(storeCurrentLists())
            dispatch(resetScores())
        }
        if (count !== leftList.length || newCount !== newSongsList.length) {
            dispatch(toggleAnimation())
        }
    }

    return (
        <Tooltip title={onSwitch === "play" ? "Pause" : "Play"}>
            <CustomFab disabled={leftList.length === 0} onClick={clickPlay}>
                {onSwitch === "play" ?
                    <PauseCircleOutlineRoundedIcon style={{ fontSize: 45 }} />
                    :
                    <PlayCircleOutlineRoundedIcon style={{ fontSize: 45 }} />
                }
            </CustomFab>
        </Tooltip>
    )
}