import { Draggable, Droppable } from "react-beautiful-dnd"
import { useSelector } from "react-redux"
import styled from "styled-components"
import LeftItem from "./LeftItem"
import SimpleBar from 'simplebar-react';
import { useRef } from "react";
import { useEffect } from "react";


const Container = styled.div`
    position: relative;
    width: 100%;
    z-index: 2;
    min-height: 100vh;
    padding: 1em 2em 1em 3em;
    z-index: 3;
    .Box {
        width: 90%;
    }
`

export default function LeftList() {
    const list = useSelector(state => state.list.left)
    const ref = useRef();

    useEffect(() => {
        ref.current.recalculate();
    }, [list])

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        margin: `0 0 .3em 0`,
        borderRadius: "5px",
        background: isDragging ? '#161616' : 'linear-gradient(90deg, rgba(0,0,0,1) 10%, rgba(255,255,255,0) 100%)',
        ...draggableStyle
    });

    return (
        <Droppable droppableId="leftList1">
            {(provided, snapshot) => (
                <SimpleBar ref={ref} style={{ height: "100vh" }}>
                    <Container
                        ref={provided.innerRef}
                    >
                        {list.map((track, index) => (
                            <Draggable
                                key={"left" + track.id}
                                draggableId={track.id}
                                index={index}>
                                {(provided, snapshot) => (
                                    <div className="Box"
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(
                                            snapshot.isDragging,
                                            provided.draggableProps.style
                                        )}
                                    >
                                        <LeftItem key={track.id} index={index} track={track} />
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </Container>
                </SimpleBar>
            )}
        </Droppable>
    )
}