import { 
    TOGGLE_RIGHT_DRAWER,
    TOGGLE_SETTINGS_DRAWER, 
    SET_BACKGROUND, 
    SET_SPEED, 
    SET_INTERVAL, 
    CHANGE_MAX, 
    INITIATE_SONG_TIER,
    RESET_SETTINGS
} from "../actions/settingsActions";


const initialState = {
    rightDrawer: true,
    settingsDrawer: false,
    backgroundType: "album-cover",
    backgroundValue: null,
    speed: 70,
    interval: 1,
    max: 20,
}

export default function settingsReducer(state = initialState, action) {
    switch(action.type) {
        case RESET_SETTINGS:
            return initialState
        case INITIATE_SONG_TIER:
            return  { ...initialState, max: 100 }
        case TOGGLE_RIGHT_DRAWER:
            return { ...state, rightDrawer: !state.rightDrawer }
        case TOGGLE_SETTINGS_DRAWER:
            return { ...state, settingsDrawer: !state.settingsDrawer }
        case SET_BACKGROUND:
            return { ...state, backgroundType: action.payload.type, backgroundValue: action.payload.type === "album-cover" ? null : action.payload.value }
        case SET_SPEED:
            return { ...state, speed: action.payload }
        case SET_INTERVAL:
            return { ...state, interval: action.payload }
        case CHANGE_MAX:
            return { ...state, max: action.payload }
        default:
            return state
    }
}