import styled from "styled-components"
import { useDispatch, useSelector } from 'react-redux';

import SettingsIcon from '@material-ui/icons/Settings';

import { toggleSettingsDrawer } from "../actions/settingsActions";

const settingIconStyle = {
    fontSize: "3.5rem",
    color: "white"
  }
  
  const SettingsDiv = styled.div`
    position: fixed;
    top: 5rem;
    right: 1.5rem;
    z-index: 4;
    opacity: .3;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out, opacity 100ms;
    cursor: pointer;
  
    &:hover {
      opacity: 1;
    }
  
    &.open {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
  `

export default function SettingsButton() {

  const settingsDrawer = useSelector(state => state.settings.settingsDrawer)
  const dispatch = useDispatch()

    return (
      <SettingsDiv className={settingsDrawer ? "open" : ""} onClick={() => dispatch(toggleSettingsDrawer())}>
            <SettingsIcon style={settingIconStyle} />
      </SettingsDiv>
    )
}