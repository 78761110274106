import { resetAnimation } from "./animationActions"

export const ADD_RIGHT_LIST = "ADD_RIGHT_LIST"
export const REMOVE_RIGHT_SONG = "REMOVE_SONG"

export const SET_LEFT_LIST = "SET_LEFT_LIST"
export const SET_RIGHT_LIST = "SET_RIGHT_LAST"
export const SET_LISTS = "SET_LISTS"

export const CLEAR_ALL = "CLEAR_ALL"

export const CHANGE_SCORE = "CHANGE_SCORE"
export const MARK_NEW_SONG = "MARK_NEW_SONG"

export const SHOW_SCORE = "SHOW_SCORE"

export const RESET_SCORES = "RESET_SCORES"
export const RESET_LEFT_LIST = "RESET_LEFT_LIST"
export const STORE_CURRENT_LISTS = "STORE_CURRENT_LISTS"

export const TOGGLE_TOAST = "TOGGLE_TOAST"

export const toggleToast = (status, message) => ({
    type: TOGGLE_TOAST,
    payload: { status, message }
})

export const addRightListCheck = (item) => {
    return (dispatch, getState) => {
        let state = getState().list
        let max = getState().settings.max
        if (state.left.length + state.right.length < max * 2) {
            if (!state.right.find(el => el.id === item.id) && !state.left.find(el => el.id === item.id)) {
                item.score = ""
                item.showScore = false
                item.isDragged = false
                item.new = false
                dispatch(addRightList(item))
                dispatch(resetAnimation())
            }
            else {
                dispatch(toggleToast("error", "This song is already added"))
            }
        }
        else {
            dispatch(toggleToast("error", "Max number of songs reached"))
        }
    }
}

export const addRightList = (item) => ({
    type: ADD_RIGHT_LIST,
    payload: item
})

export const moveLeftListCheck = (item, source, dest) => {
    return (dispatch, getState) => {
        let state = getState().list
        let max = getState().settings.max
        if (item.new) {
            dispatch(toggleToast("error", "Cannot add new songs"))
        }
        else if (state.left.find(el => el.id === item.id)) {
            dispatch(toggleToast("error", "This song is already on left list"))
        }
        else if (state.left.length >= max) {
            dispatch(toggleToast("error", "Left list is already full"))
        }
        else {
            let leftcopy = state.left.slice()
            let rightcopy = state.right.slice()
            leftcopy.splice(source, 0, item)
            rightcopy.splice(dest, 1)
            if (leftcopy[source].score === "") {
                leftcopy[source].score = 0
            }
            dispatch(setLists(leftcopy, rightcopy))
        }
    }
}

export const moveRightListCheck = (item, source, dest) => {
    return (dispatch, getState) => {
        let state = getState().list
        if (!state.right.find(el => el.id === item.id)) {
            let leftcopy = state.left.slice()
            let rightcopy = state.right.slice()
            rightcopy.splice(source, 0, item)
            leftcopy.splice(dest, 1)
            if (rightcopy[source].score === 0) {
                rightcopy[source].score = ""
            }
            dispatch(setLists(leftcopy, rightcopy))
        }
        else {
            dispatch(toggleToast("error", "This song is already on right list"))
        }
    }
}

export const setLists = (left, right) => ({
    type: SET_LISTS,
    payload: { left, right }
})

export const setLeftList = (items) => ({
    type: SET_LEFT_LIST,
    payload: items
})

export const setRightList = (items) => ({
    type: SET_RIGHT_LIST,
    payload: items
})

export const removeRightSong = (id) => ({
    type: REMOVE_RIGHT_SONG,
    payload: id
})

export const clearAll = () => ({
    type: CLEAR_ALL
})

export const moveEverything = (direction) => {
    return (dispatch, getState) => {
        let state = getState().list
        let max = getState().settings.max
        if (direction === "right") {
            let leftcopy = state.left.map(el => el.score === 0 ? { ...el, score: "", showScore: false } : { ...el, showScore: false })
            dispatch(setLists([], [...leftcopy, ...state.right]))
        }
        else {
            let total = [...state.left]
            let rightcopy = state.right.map(el => el.score === "" ? { ...el, score: 0 } : el)
            for (let i = 0; i < rightcopy.length; i++) {
                if (total.length < max && !rightcopy[i].new) {
                    total.push(...rightcopy.splice(i, 1))
                    i--
                }
            }
            dispatch(setLists(total, rightcopy))
        }
        dispatch(resetAnimation())
    }
}

export const changeScore = (index, score) => ({
    type: CHANGE_SCORE,
    payload: { index, score }
})

export const showScore = () => ({
    type: SHOW_SCORE
})

export const resetScores = () => ({
    type: RESET_SCORES
})

export const resetLeftList = () => ({
    type: RESET_LEFT_LIST
})

export const markNewSong = (index) => ({
    type: MARK_NEW_SONG,
    payload: index
})

export const storeCurrentLists = () => ({
    type: STORE_CURRENT_LISTS
})

export const replaceWithNewSong = () => {
    return (dispatch, getState) => {
        let state = getState()
        let song = state.list.newSongs[state.animation.newCount]
        let rightcopy = state.list.right.slice()
        let leftcopy = state.list.left.slice()
        let right = rightcopy.filter(el => el.id !== song.id)
        leftcopy[leftcopy.length - 1] = song
        dispatch(setLists(leftcopy, right))
    }
}