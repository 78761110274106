import axios from "axios"
import { toggleToast } from "./listActions"

export const SUGGESTIONS_RETRIEVED = "SUGGESTIONS_RETRIEVED"

export const suggestionsRetrieved = (items) => ({
    type: SUGGESTIONS_RETRIEVED,
    payload: items
})

export const fetchSuggestions = (input) => {
    return async (dispatch, getState) => {
        try {
            if (!input) dispatch(suggestionsRetrieved([]))
            else {
                let offset = 0
                let response = await axios.get(`https://api.spotify.com/v1/search?q=${input.replace(/\s/g, "+")}&type=track&limit=5&offset=${offset}`)
                let data = response.data.tracks.items
                let list = data
                list = list.filter(el => !getState().list.left.find(track => track.id === el.id) && !getState().list.right.find(track => track.id === el.id))
                while (list.length !== 5 && data.length !== 0) {
                    offset += 5
                    response = await axios.get(`https://api.spotify.com/v1/search?q=${input.replace(/\s/g, "+")}&type=track&limit=5&offset=${offset}`)
                    data = response.data.tracks.items
                    list = [...list, ...data]
                    list = list.filter(el => !getState().list.left.find(track => track.id === el.id) && !getState().list.right.find(track => track.id === el.id)).slice(0, 5)
                }
                dispatch(suggestionsRetrieved(list))
            }
        }
        catch (e) {
            dispatch(toggleToast("error", "Search error"))
        }
    }
}
