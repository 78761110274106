import { useSelector, useDispatch } from "react-redux";

import { FormControl, MenuItem, TextField } from "@material-ui/core";

import { setChangeMax } from "../../actions/settingsActions";

export default function MaxSongs() {

    const dispatch = useDispatch()
    const max = useSelector(state => state.settings.max)

    return (
        <FormControl style={{ width: "70%", display: "flex", flexDirection: "column", gap: "1em" }}>
            <h3>
                Maximum no. of songs
            </h3>
            <TextField
                id="max-songs"
                select
                value={max}
                defaultValue={max}
                variant="outlined"
                style={{ textAlign: "right" }}
                onChange={(e) => dispatch(setChangeMax(e.target.value))}
            >
                <MenuItem value={20}>20</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={50}>50</MenuItem>
            </TextField>
        </FormControl>
    )
}