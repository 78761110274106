export default function moveStepByStep(drag, points) {
    requestAnimationFrame(() => {
        let newPosition = points.shift();
        drag.move(newPosition)

        if (points.length) {
            moveStepByStep(drag, points)
        }
        else {
            drag.drop()
        }
    });
}
