import { SUGGESTIONS_RETRIEVED } from "../actions/searchActions"

const initialState = {
    suggestions: []
}

const searchReducer = (state = initialState, action) => {
    switch (action.type) {
        case SUGGESTIONS_RETRIEVED:
            return { ...state, suggestions: action.payload }
        default:
            return state
    }
}

export default searchReducer