import { applyMiddleware, combineReducers, createStore } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import animationReducer from "../reducers/animationReducer";
import listReducer from "../reducers/listReducer";
import searchReducer from "../reducers/searchReducer";
import settingsReducer from "../reducers/settingsReducer";

const netReducer = combineReducers({
    search: searchReducer, 
    list: listReducer, 
    animation: animationReducer, 
    settings: settingsReducer
})

const store = createStore(netReducer, applyMiddleware(logger, thunk))

export default store