import styled from "styled-components"

const Container = styled.div`
    position: relative;
    left: 0%;
    padding: .3em .5em;
    display: flex;
    align-items: center;
    gap: .5em;
    border-radius: 5px;
    transition: left 300ms ease-out;
    color: white;
    font-size: 1.1rem;
    backdrop-filter: blur(2px);
    &:hover { 
        background: #00000065;
    }
    .info {
        display: flex;
        flex-direction: column;
        margin-left: 1em;
    }
    .title {
        font-weight: 700;
    }
    .artist {
        font-size: 0.7em;
        opacity: 0.8;
    }
    .imgdiv {
        width: 7vh;
        height: 7vh;
        min-height: 32px;
        min-width: 32px;
        display: flex;
        img {
            object-fit: cover;
        }
    }
    .rank {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: .5em;
        width: 7vh;
        height: 100%;
        line-height: 1;
        font-weight: 700;
        font-size: 1.2em;
    }
`

export default function LeftItem({ track, index }) {
    return (
        <Container>
            <div className="rank">
                {index + 1}
            </div>
            <div className="imgdiv">
                <img src={track.album.images[0].url} alt="album art" />
            </div>
            <div className="info">
                <div className="title">{track.name}</div>
                <div className="artist">{track.artists.map(el => el.name).join(" | ")}</div>
            </div>
        </Container>
    )
}