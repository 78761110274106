
import axios from "axios";

const encoded = "YWYyMDVlZGU4OTYyNDVjMDk2MzA0YzY3MjVlMGY2NGI6MDFiNjBhZjMzYzUxNDU1MmE2ZGY3YTAxZDIwNWZiMWY="
const tokenURL = "https://accounts.spotify.com/api/token"

export default function initAxios() {
    axios.interceptors.request.use(
        function (req) {
            if (req.url === tokenURL) {
                req.headers["Authorization"] = "Basic  " + encoded;
            }
            else {
                let token = localStorage.getItem("access_token");
                if (!token) {
                    console.log("Token not found");
                } else {
                    req.headers["Authorization"] = "Bearer " + token;
                }
            }
            return req;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    async function getToken() {
        const params = new URLSearchParams();
        params.append('grant_type', 'client_credentials');
        let response = await axios.post(tokenURL, params);
        let data = response.data;
        localStorage.setItem("access_token", data.access_token);
    }

    axios.interceptors.response.use(
        function (res) {
            return res;
        },
        async function (error) {
            let originalRequest = error.config;
            if (error.response.status === 401) {
                await getToken()
                return axios(originalRequest);
            }
            return Promise.reject(error);
        }
    );
}