
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components"

import { InputAdornment, OutlinedInput, withStyles } from "@material-ui/core"

import { setAnimationInterval } from "../../actions/settingsActions";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1em;
    width: 70%;

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

const CustomField = withStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '&.Mui-focused': {
                borderColor: 'rgb(39,44,52)',
                borderWidth: "2px"
            },
        },
    },
})(OutlinedInput);

export default function Interval() {

    const value = useSelector(state => state.settings.interval)
    const dispatch = useDispatch()

    return (
        <Container>
            <h3>
                Animation Interval
            </h3>
            <CustomField
                type="number"
                value={value}
                onChange={(e) => dispatch(setAnimationInterval(e.target.value === "" ? "" : Math.abs(e.target.value)))}
                endAdornment={<InputAdornment position="end">seconds</InputAdornment>}
                inputProps={{
                    'aria-label': 'weight'
                }}
                labelWidth={0}
            />
        </Container>
    )
}