
import { useDispatch, useSelector } from "react-redux"
import styled from "styled-components"
import { useState } from "react"

import { Autocomplete } from "@material-ui/lab"
import { Grid, TextField } from "@material-ui/core"
import { addRightListCheck } from "../../actions/listActions"
import SearchIcon from '@material-ui/icons/Search';

import { fetchSuggestions, suggestionsRetrieved } from "../../actions/searchActions"


const Container = styled.div`
    width: 100%;
    display: flex;

    .box {
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: .5em .8em;
        background-color: rgb(39,44,52);
        border-bottom-left-radius: 5px;
        border-top-left-radius: 5px;
    }
`
const SuggestionImg = styled.div`
    width: 50px;
    height: 50px;
    display: flex;

    img {
        object-fit: cover;
    }
`

const SuggestionDiv = styled.div`
    padding: .5em;
    display: flex;
    gap: 0.5em;
    align-items: center;

    .info {
        display: flex;
        flex-direction: column;
    }

    .title {
        font-weight: 700;
    }

    .artist {
        font-size: 0.8rem;
        opacity: 0.8;
    }
`


export default function Searchbar() {

    const suggestions = useSelector(state => state.search.suggestions)
    const dispatch = useDispatch()

    let [term, setTerm] = useState("")
    let [input, setInput] = useState("")

    const songChosen = (value) => {
        if (value?.name) {
            setInput(value)
            dispatch(addRightListCheck(value))
        }
    }

    return (
        <Container>
            <div className="box">
                <SearchIcon style={{
                    color: "white", fontSize: "1.8rem"
                }} />
            </div>
            <Autocomplete
                style={{ flex: 1, position: "relative", left: "-5px" }}
                noOptionsText="Search for songs"
                options={suggestions}
                disableCloseOnSelect
                selectOnFocus
                freeSolo
                inputValue={term}
                value={input}
                filterOptions={x => x}
                getOptionLabel={(option) => term}
                onChange={(event, newValue) => {
                    songChosen(newValue)
                    dispatch(fetchSuggestions(term))
                }}
                onClose={(event, reason) => {
                    if (input !== "") {
                        setTerm("")
                        dispatch(suggestionsRetrieved([]))
                    }
                    setInput("")
                }}
                onInputChange={(event, newInputValue) => {
                    setTerm(newInputValue)
                    dispatch(fetchSuggestions(newInputValue))
                }}
                renderInput={(params) => (
                    <TextField {...params}
                        label="Add songs..."
                        variant="outlined" />
                )}
                renderOption={(option) => {
                    return (
                        <Grid container alignItems="center">
                            <Grid item xs>
                                <SuggestionDiv>
                                    <SuggestionImg>
                                        <img src={option.album.images[0].url} alt="album art" />
                                    </SuggestionImg>
                                    <div className="info">
                                        <div className="title">{option.name}</div>
                                        <div className="artist">{option.artists.map(el => el.name).join(" | ")}</div>
                                    </div>
                                </SuggestionDiv>
                            </Grid>
                        </Grid>
                    );
                }}
            />
        </Container>
    )
}