
import styled from "styled-components"

const Container = styled.div`
    width: calc(50% - 60px);
    padding: .5em;
    display: flex;
    gap: 0.5em;
    align-items: center;

    .info {
        display: flex;
        flex-direction: column;
    }

    .title {
        font-weight: 700;
    }

    .artist {
        font-size: 0.8rem;
        opacity: 0.8;
    }

    .imgdiv {
        width: 50px;
        height: 50px;
        display: flex;

        img {
            object-fit: cover;
        }
    }
`

export default function Front({ track }) {
    return (
        <Container>
            <div className="imgdiv">
                <img src={track.album.images[0].url} alt="album art" />
            </div>
            <div className="info">
                <div className="title">{track.name}</div>
                <div className="artist">{track.artists.map(el => el.name).join(" | ")}</div>
            </div>
        </Container>
    )
}