import { useDispatch, useSelector } from "react-redux"

import { Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"

import { toggleToast } from "../actions/listActions"

export default function ErrorAlert() {

  const toast = useSelector(state => state.list.toast)
  const dispatch = useDispatch()

    return (
        <Snackbar open={toast.open} autoHideDuration={1000} onClose={() => dispatch(toggleToast())}>
            <Alert elevation={6} variant="filled" onClose={() => dispatch(toggleToast())} severity={toast.status}>
                {toast.message}
            </Alert>
        </Snackbar>
    )
}