
const reorder = (list, startIndex, endIndex) => {
    let result = list.slice()
    const [removed] = result.splice(startIndex, 1);
    removed.open = false
    removed.isDragged = false
    result.splice(endIndex, 0, removed);
    return result;
  };

export default reorder