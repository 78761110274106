import tweenFunctions from "tween-functions";
import moveStepByStep from "./moveTiles.js";

const startDrag = (api, item, states) => {
    const preDrag = api.tryGetLock(item.id);
    if (!preDrag) {
        return;
    }

    const { leftList, max, speed, tileWidth, count } = states

    let index = computeIndex(item, leftList, count)
    let length = leftList.length

    const height = {
        20: 60,
        30: 42,
        50: 26
    }

    const gap = {
        20: 32,
        30: 32,
        50: 25
    }


    const baseSpeed = (350 - (speed * 3))

    const start = { x: 0, y: 0 }
    let end

    if (length > max / 2 && index < max / 2) {
        const tiles = (max / 2) - length + index + 1
        const tileGap = 1.6 * (tiles)
        const tileDistance = (tiles * height[max]) + tileGap
        end = { x: -1 * (tileWidth + gap[max]), y: tileDistance };
    }
    else {
        if (index >= max / 2) {
            index = index - max / 2
            length = length - max / 2
        }

        const tiles = (length - 1) - index
        let extra = 0
        if (max === 50 && tiles === 1) {
            extra = height[max] / 2
        }
        const tileGap = 1.6 * (tiles)
        const tileDistance = (tiles * height[max] + tileGap) + extra
        end = { x: 0, y: -1 * tileDistance };
    }

    const drag = preDrag.fluidLift(start);
    const distance = Math.sqrt((start.x - end.x) ** 2 + (start.y - end.y) ** 2)
    const frames = baseSpeed * (distance / 1000)
    const points = createPoints(start, end, frames)
    setTimeout(() => moveStepByStep(drag, points), 2500)
};


function createPoints(start, end, speed) {
    let points = []
    for (let i = 0; i < speed; i++) {
        points.push({
            x: tweenFunctions.easeOutCirc(i, start.x, end.x, speed),
            y: tweenFunctions.easeOutCirc(i, start.y, end.y, speed)
        });
    }
    return points
}


// helper to find correct position of tile
const computeIndex = (item, leftList, count) => {
    if (count === leftList.length) count = leftList.length - 1
    const scored = leftList.slice(0, count)
    let index = 0;

    for (let i = count - 1; i >= 0; i--) {
        if (scored[i].score >= item.score) {
            index = i + 1;
            break;
        }
    }

    return index
}


export default startDrag