
import { useSelector } from "react-redux"
import styled from "styled-components"
import { Droppable, Draggable } from 'react-beautiful-dnd';

import RightItem from "./RightItem/RightItem"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
    flex: 1;
    padding: 0em 2em;
    background-color: white;
`

export default function RightList({ page }) {

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        margin: `0 0 1em 0`,
        borderRadius: "5px",
        background: isDragging ? '#afb6c0' : 'white',
        ...draggableStyle
    });

    let list = useSelector(state => state.list.right)

    return (
        <Droppable droppableId="rightList">
            {(provided, snapshot) => (
                <Container
                    ref={provided.innerRef}
                >
                    {list.map((track, index) => (
                        <Draggable
                            key={track.id}
                            draggableId={"right" + track.id}
                            index={index}>
                            {(provided, snapshot) => (
                                <div className="Box"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                        snapshot.isDragging,
                                        provided.draggableProps.style
                                    )}
                                >
                                    <RightItem page={page} key={track.id} index={index} track={track} />
                                </div>
                            )}
                        </Draggable>
                    ))}
                    {provided.placeholder}
                </Container>
            )}
        </Droppable>
    )
}

