import {
    ADD_RIGHT_LIST,
    REMOVE_RIGHT_SONG,
    SET_LEFT_LIST,
    SET_RIGHT_LIST,
    SET_LISTS,
    CLEAR_ALL,
    CHANGE_SCORE,
    SHOW_SCORE,
    RESET_SCORES,
    RESET_LEFT_LIST,
    MARK_NEW_SONG,
    TOGGLE_TOAST,
    STORE_CURRENT_LISTS,
} from "../actions/listActions"

const initialState = {
    right: [],
    left: [],
    toast: { open: false, status: "error", message: "" },
    newSongs: [],
    storedLists: { left: [], right: [], newSongs: [] }
}

export default function listReducer(state = initialState, action) {
    let leftcopy, rightcopy
    switch (action.type) {
        case ADD_RIGHT_LIST:
            return { ...state, right: [...state.right, action.payload] }
        case SET_LISTS:
            return { ...state, left: action.payload.left, right: action.payload.right }
        case SET_LEFT_LIST:
            return { ...state, left: action.payload }
        case SET_RIGHT_LIST:
            return { ...state, right: action.payload }
        case REMOVE_RIGHT_SONG:
            let filtered = state.right.filter(el => el.id !== action.payload)
            return { ...state, right: filtered }
        case CLEAR_ALL:
            return { ...state, left: [], right: [] }
        case CHANGE_SCORE:
            rightcopy = state.right.slice()
            rightcopy[action.payload.index].score = action.payload.score
            return { ...state, right: rightcopy }
        case SHOW_SCORE:
            leftcopy = state.left.slice()
            leftcopy[leftcopy.length - 1].showScore = true
            leftcopy[leftcopy.length - 1].isDragged = true
            return { ...state, left: leftcopy }
        case RESET_SCORES:
            let reset = state.left.slice()
            reset.forEach(el => el.showScore = false)
            return { ...state, left: reset }
        case RESET_LEFT_LIST:
            return { ...state, left: state.storedLists.left.map(el => ({ ...el, showScore: false })), right: state.storedLists.right, newSongs: state.storedLists.newSongs }
        case MARK_NEW_SONG:
            rightcopy = state.right.slice()
            rightcopy[action.payload].new = !rightcopy[action.payload].new
            return { ...state, right: rightcopy }
        case TOGGLE_TOAST:
            return { ...state, toast: { open: !state.toast.open, status: action.payload.status || state.toast.status, message: action.payload.message || state.toast.message } }
        case STORE_CURRENT_LISTS:
            let newSongs = state.right.filter(el => el.new)
            return { ...state, newSongs, storedLists: { left: state.left, right: state.right, newSongs: newSongs } }
        default:
            return state
    }
}