import styled from "styled-components"
import { useSelector } from "react-redux"

import Burger from "../Burger"
import MaxSongs from "./MaxSongs"
import Speed from "./Speed"
import Interval from "./Interval"
import Background from "./Background"

const Container = styled.div`
    position: fixed;
    right: ${props => props.open ? "0%" : "-30%"};
    flex-shrink: 0;
    max-width: 450px;
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    background-color: white;
    z-index: 6;
    height: 100vh;
    transition: all 250ms ease-in-out;

    h1 {
        font-size: 2.2rem;
    }
`

const Header = styled.div`
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    width: 100%;
    padding: 4.5em 2em 0em 2em;
    position: sticky;
    top: 0em;
    z-index: -1;
`

const Body = styled.div`
    padding: 2em;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5em;

    .speed, .background {
        display: flex;
        flex-direction: column;
        gap: 1em;
    }
`

export default function Settings() {

    const settingsDrawer = useSelector(state => state.settings.settingsDrawer)

    return (
        <Container open={settingsDrawer}>
            <Burger settings={true} />
            <Header>
                <h1>Settings</h1>
            </Header>
            <Body>
                <MaxSongs />
                <Speed />
                <Interval />
                <Background />
            </Body>
        </Container>
    )
}