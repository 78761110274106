import initAxios from "../axios-config";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core";

import Landing from "./Landing";
import SongTier from "./SongTier/SongTier";
import Scoreboard from "./Scoreboard";

function App() {

  initAxios()

  const theme = createTheme({
    palette: {
      primary: {
        main: "rgb(39,44,52)",
      },
      secondary: {
        main: "#f3b711f6"
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/scoreboard">
            <Scoreboard />
          </Route>
          <Route path="/songtier">
            <SongTier />
          </Route>
          <Route path="/">
            <Landing />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;