import styled from "styled-components"
import Left from "./Left"
import { DragDropContext } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from "react-redux";
import handleDragEnd from "../../helpers/handleDragEnd";
import RightDrawer from "../RightDrawer/RightDrawer";
import { useEffect } from "react";
import { initiateSongTier } from "../../actions/settingsActions";
import Background from "../Background";
import { clearAll } from "../../actions/listActions";
import useDocumentTitle from "../../helpers/useDocumentTitle";

const Container = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    background-color: rgb(39,44,52);
`

export default function Main() {

    let leftList = useSelector(state => state.list.left)
    let rightList = useSelector(state => state.list.right)
    let dispatch = useDispatch()

    let onDragEnd = result => {

        if (!result.destination) {
            return;
        }

        const dragEndStates = {
            rightList,
            leftList
        }

        handleDragEnd(dispatch, result, dragEndStates)
    };

    useDocumentTitle("Song Tier")

    useEffect(() => {
        dispatch(clearAll())
        dispatch(initiateSongTier())
        // eslint-disable-next-line
    }, [])

    return (
        <DragDropContext onDragEnd={onDragEnd} class="App">
            <Container>
                <Background />
                <Left />
                <RightDrawer page="Song Tier" />
            </Container>
        </DragDropContext>
    )
}