import { useDispatch } from "react-redux";
import styled from "styled-components";

import { Checkbox, FormControlLabel, IconButton, TextField } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import FiberNewOutlinedIcon from '@material-ui/icons/FiberNewOutlined';
import FiberNewIcon from '@material-ui/icons/FiberNew';

import { changeScore, markNewSong } from "../../../actions/listActions";


const Container = styled.div`
    width: calc(50% - 60px);
    height: 100%;
    display: flex;
    align-items: center;
    
    .close {
        padding: 0em .5em;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`

export default function Back({ track, index, removeSong }) {

    let dispatch = useDispatch()

    return (
        <Container>
            <FormControlLabel
                size="small"
                style={{margin: 0, marginRight: "3px"}}
                labelPlacement="top"
                control={<Checkbox 
                    size="small"
                    icon={<FiberNewOutlinedIcon color="disabled" style={{fontSize: "40px"}} />} 
                    checkedIcon={<FiberNewIcon style={{fontSize: "40px"}} />} 
                    color="secondary" 
                    checked={track.new} 
                    onChange={() => dispatch(markNewSong(index))} />}   
            />  
            <TextField
                variant="outlined"
                placeholder="Score"
                style={{ flex: 1 }}
                type="number"
                value={track.score}
                onChange={(e) => dispatch(changeScore(index, parseInt(e.target.value)))}
                onWheel={(e) => e.target.blur()}
            />
            <div className="close">
                <IconButton color="default" onClick={() => removeSong(track.id)}>
                    <DeleteIcon />
                </IconButton>
            </div>
        </Container>
    )
}